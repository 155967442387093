import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { amber, lightBlue } from '@material-ui/core/colors';
import HttpsRedirect from 'react-https-redirect';

const theme = createMuiTheme({
    palette: {
      primary: amber,
      secondary: lightBlue,
      text: {
          primary: "#000000"
      }
    },
  });

ReactDOM.render(
  <HttpsRedirect>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </HttpsRedirect>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
